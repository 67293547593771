import { EmbedHTMLAttributes } from "react";

type EmbedProps = EmbedHTMLAttributes<any>;

export const Embed = ({
  src,
  width = "810px",
  height = "810px",
  type = "application/pdf",
}: EmbedProps) => (
  <div className="flex justify-center items-center">
    <embed
      className="justify-center"
      data-testid="embed"
      src={src + "#toolbar=0&navpanes=0&scrollbar=0view=fit"}
      style={{ flex: "70%" }}
      width={width}
      height={height}
      type={type}
    />
  </div>
);
