import env from "../config/env";

export async function downloadCall(
  id: string,
  token: string,
  defaultFilename = "example.pdf"
) {
  try {
    const blob = await fetchAttachment(id, token);
    const filename = getFilenameFromResponseHeaders(blob, defaultFilename);
    downloadBlobAsFile(blob, filename);
  } catch (error) {
    console.error("Error downloading attachment: ", error);
  }
}

export async function fetchAttachment(id: string, token: string) {
  // if (!new RegExp("/api/export/download\\?filename=").test(url))
  //   throw new Error("SSRF Attack detected!");

  const response = await fetch(
    `${env.REACT_APP_API_URL}/api/export/download?` +
      new URLSearchParams({
        filename: id,
      }).toString(),
    {
      headers: {
        "content-disposition": "attachment",
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.blob();
}

export function getFilenameFromResponseHeaders(
  blob: Blob,
  defaultFilename: string
) {
  const contentDisposition =
    blob.type === "application/json" ? null : blob.type;
  let filename = defaultFilename;

  if (contentDisposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }

  return filename;
}

export function downloadBlobAsFile(blob: Blob, filename: string) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("style", "display:none;");
  document.body.appendChild(a);
  a.href = url;
  a.download = filename;
  a.click();
  a.remove();
}
