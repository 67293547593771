/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedModelEntityModelStatusDto } from "../models/PagedModelEntityModelStatusDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class StatusControllerService {
  /**
   * Returns a paged list of StatusDto entities
   * @returns PagedModelEntityModelStatusDto Paged list of StatusDto entities
   * @throws ApiError
   */
  public static getAllStatuses({
    page,
    size = 10,
    sort,
    code,
    id,
    text,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    code?: string;
    id?: number;
    text?: string;
  }): CancelablePromise<PagedModelEntityModelStatusDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/status",
      query: {
        page: page,
        size: size,
        sort: sort,
        code: code,
        id: id,
        text: text,
      },
    });
  }
}
