import {
  ZfCheckbox,
  ZfLoadingSpinner,
  ZfSearchField,
  ZfTable,
} from "@ccx/zafire-react";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import { FC, useEffect, useRef, useState } from "react";
import authorizedCall from "../../utils/authorizedCallUtils";
import { NostroControllerService } from "../../openapi";

export const AccountViewEditAccountBalanceHistory: FC<{
  accId: number;
}> = (props) => {
  const [error, setError] = useState(false);

  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);
  const dataTable = useRef<HTMLZfTableElement>(null);

  const [accBalFetching, setAccBalFetching] = useState(false);
  const [filterBreached, setFilterBreached] = useState({
    kstaValueChfBreach: false,
    kstaValueCcyBreach: false,
    busaldoValueChfBreach: false,
    busaldoValueCcyBreach: false,
  });

  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    const TABLE_COLUMNS = [
      { title: "Account Number", field: "number" },
      { title: "CCY", field: "currency" /*, headerFilter: 'input'*/ },
      { title: "COB_DT", field: "cobDate" },
      { title: "Description", field: "description" },
      {
        title: "KSTA_VAL_CHF",
        field: "kstaValueChf",
        formatter: (cell: any) => formatCell(cell, "kstaValueChfBreach"),
      },
      {
        title: "KSTA_VAL_CCY",
        field: "kstaValueCcy",
        formatter: (cell: any) => formatCell(cell, "kstaValueCcyBreach"),
      },
      { title: "BuSa_DT", field: "busaldoDate" },
      {
        title: "BuSaldo_VAL_CHF",
        field: "busaldoValueChf",
        formatter: (cell: any) => formatCell(cell, "busaldoValueChfBreach"),
      },
      {
        title: "BuSaldo_VAL_CCY",
        field: "busaldoValueCcy",
        formatter: (cell: any) => formatCell(cell, "busaldoValueCcyBreach"),
      },
    ];

    const actionColumns: [] = [];

    async function dataTableApiCall(url: any, config: any, params: any) {
      console.log("dataTableCall");

      setAccBalFetching(true);
      const sortArray: Array<string> =
        params.sort.length > 0
          ? [`${params.sort[0].field},${params.sort[0].dir}`]
          : [];
      let apiParams: Parameters<
        typeof NostroControllerService.getAllAccountBalanceHistory
      >[0] = {
        nostroPathId: props.accId,
        page: params.page,
        size: params.size,
        sort: sortArray,
      };

      if (searchFilter.current?.value) {
        apiParams = { ...apiParams, search: searchFilter.current?.value };
      }

      if (filterBreached.kstaValueChfBreach) {
        apiParams = { ...apiParams, kstaValueChfBreach: filterBreached.kstaValueChfBreach };
      }
      if (filterBreached.kstaValueCcyBreach) {
        apiParams = { ...apiParams, kstaValueCcyBreach: filterBreached.kstaValueCcyBreach };
      }
      if (filterBreached.busaldoValueChfBreach) {
        apiParams = { ...apiParams, busaldoValueChfBreach: filterBreached.busaldoValueChfBreach };
      }
      if (filterBreached.busaldoValueCcyBreach) {
        apiParams = { ...apiParams, busaldoValueCcyBreach: filterBreached.busaldoValueCcyBreach };
      }

      return authorizedCall(
        NostroControllerService.getAllAccountBalanceHistory,
        apiParams,
        true
      )
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          /*do nothing*/
        })
        .finally(() => {
          setAccBalFetching(false);
        });
    }

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
    };
  }, [props.accId, filterBreached]);

  function refreshTable(event: any) {
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  console.log("RENDER - AccountView [commFetching:", accBalFetching, "]");

  function formatCell(cell: any, controlSource: string) {
    if (cell.getData()[controlSource])
      return `<label style="color: white; background-color: red">${cell.getValue()}</label>`;

    return cell.getValue();
    //return `<label style="color:${color}; background-color:${bgcolor}">${cell.getValue()}</label>`;
  }

  return (
    <>
      <div
        className="flex flex-row pb-6"
        style={{ borderBottom: "1px solid #ddd" }}
      >
        <div className="basis-1/2"></div>
        <div className="basis-1/4">
          <ZfSearchField
            placeholder="Search ..."
            onInputChange={(e) => {
              refreshTable(e);
            }}
            ref={searchFilter}
          ></ZfSearchField>
        </div>
        <div className="basis-1/4"></div>
        <div className="basis-1/4">
          <ZfCheckbox
            label="KSTA_VAL_CHF Breached"
            //checked={formData?.largeTurnover.value}
            onCheckboxCheck={(event) => {
              setFilterBreached({ ...filterBreached, kstaValueChfBreach: event.detail });
            }}
            name="KSTA_VAL_CHF"
          ></ZfCheckbox>
          <ZfCheckbox
            label="KSTA_VAL_CCY Breached"
            //checked={formData?.largeTurnover.value}
            onCheckboxCheck={(event) => {
              setFilterBreached({ ...filterBreached, kstaValueCcyBreach: event.detail });
            }}
            name="KSTA_VAL_CCY"
          ></ZfCheckbox>
          <ZfCheckbox
            label="BuSaldo_VAL_CHF Breached"
            //checked={formData?.largeTurnover.value}
            onCheckboxCheck={(event) => {
              setFilterBreached({ ...filterBreached, busaldoValueChfBreach: event.detail });
            }}
            name="BuSaldo_VAL_CHF"
          ></ZfCheckbox>
          <ZfCheckbox
            label="BuSaldo_VAL_CCY Breached"
            //checked={formData?.largeTurnover.value}
            onCheckboxCheck={(event) => {
              setFilterBreached({ ...filterBreached, busaldoValueCcyBreach: event.detail });
            }}
            name="BuSaldo_VAL_CCY"
          ></ZfCheckbox>
        </div>
      </div>

      {error && <p className="error">Error in loading data</p>}
      {accBalFetching && (
        <ZfLoadingSpinner
          size="large"
          color="primary"
          type="infinite"
          style={{
            position: "absolute",
            left: "50%",
            right: "50%",
            zIndex: "100",
            top: "35%",
          }}
          data-testid="spinner"
        ></ZfLoadingSpinner>
      )}

      <ZfTable
        ref={dataTable}
        onTableBuilt={(event) => tableBuiltHandler(event, setAccBalFetching)}
        className="mt-5"
        data-testid="vrm-data-table"
        style={{
          pointerEvents: accBalFetching && "none",
          opacity: accBalFetching && 0.7,
        }}
      ></ZfTable>
    </>
  );
};
