import { Navigate, Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import { AccountView } from "../pages/AccountView";
import AccountCategories from "../pages/AccountCategories";
import AccountCategoriesEdit from "../pages/AccountCategoriesEdit";
import ViewRelationshipManager from "../pages/ViewRelationshipManager";
import { useAppDispatch } from "../../store/store";
import { appStateActions } from "../../store/appstate-slice";
import CleanState from "./CleanState";
import { AccountViewEdit } from "../pages/AccountViewEdit";
import { AccountCommentEdit } from "../pages/AccountCommentEdit";
import NostroVsAccountBalance from "../pages/NostroVsAccountBalance";
import AccountBalanceLimitBreach from "../pages/AccountBalanceLimitBreach";
import AccountBalanceCobDate from "../pages/AccountBalanceCobDate";
import AccountBalanceMissingCob from "../pages/AccountBalanceMissingCob";
import OtherNostroViews from "../pages/OtherNostroViews";
import { AccountLimitEdit } from "../pages/AccountLimitEdit";
import EmailPage from "../pages/EmailPage";
import { EmailTemplatePage } from "../pages/EmailTemplatePage";
import { ReportPreview } from "../pages/ReportPreview";

export const AppRoutes = () => {
  const dispatch = useAppDispatch();
  dispatch(appStateActions.error(undefined));
  return (
    <CleanState>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/acc-view" element={<AccountView />} />
        <Route path="/other-views" element={<OtherNostroViews />} />
        <Route path="/acc-view/:id" element={<AccountViewEdit />} />
        <Route path="/acc-cat-view" element={<AccountCategories />} />
        <Route path="/acc-cat-view/:id" element={<AccountCategoriesEdit />} />
        <Route path="/acc-cat-view/new" element={<AccountCategoriesEdit />} />
        <Route
          path="/email-without-balance"
          element={<EmailPage type="WITHOUT_BALANCE" />}
        />
        <Route
          path="/email-with-balance"
          element={<EmailPage type="WITH_BALANCE" />}
        />
        <Route
          path="/email-wo-bal-templ"
          element={<EmailTemplatePage type="WITHOUT_BALANCE" />}
        />
        <Route
          path="/email-w-bal-templ"
          element={<EmailTemplatePage type="WITH_BALANCE" />}
        />
        <Route
          path="/other-views/nos-vs-acc-bal"
          element={<NostroVsAccountBalance />}
        />
        <Route
          path="/other-views/acc-bal-lim-br"
          element={<AccountBalanceLimitBreach />}
        />
        <Route
          path="/other-views/cob-dt-vs-acc-bal"
          element={<AccountBalanceCobDate />}
        />
        <Route
          path="/other-views/cob-dt-missing"
          element={<AccountBalanceMissingCob />}
        />
        <Route
          path="/view-relationship-manager"
          element={<ViewRelationshipManager />}
        />
        <Route
          path="/view-relationship-manager/:id"
          element={<ViewRelationshipManager />}
        />
        <Route
          path="/acc-comment-edit/:accId/:commId"
          element={<AccountCommentEdit />}
        />
        <Route
          path="/acc-limit-edit/:accId/new"
          element={<AccountLimitEdit />}
        />
        <Route
          path="/rep-acc-bal-exp/:id?"
          element={<ReportPreview type="ACC_BAL_EXP" />}
        />
        <Route
          path="/rep-acc-bal-lim-br/:id?"
          element={<ReportPreview type="ACC_BAL_LIM_BR" />}
        />
        <Route
          path="/rep-nos-com/:id?"
          element={<ReportPreview type="ACC_COMMENTS" />}
        />
      </Routes>
    </CleanState>
  );
};
