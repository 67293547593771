import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/store";
import { useEffect, useState } from "react";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import {
  ZfCheckbox,
  ZfInputField,
  ZfLoadingSpinner,
  ZfSelectField,
  ZfSelectOption,
  ZfTextButton,
  ZfTextareaField,
} from "@ccx/zafire-react";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  EntityModelNostroCategoryDto,
  EntityModelNostroRiskDto,
  EntityModelStatusDto,
  NostroCategoryControllerService,
  NostroControllerService,
  NostroDetailDto,
  NostroRiskComplianceControllerService,
  NostroRiskFraudControllerService,
  PagedModelEntityModelAccountLimitDto,
  PagedModelEntityModelNostroCategoryDto,
  PagedModelEntityModelNostroRiskDto,
  StatusControllerService,
} from "../../openapi";
import { AppError, appStateActions } from "../../store/appstate-slice";
import { AccountViewEditTabs } from "./AccountViewEditTabs";

interface FormInput {
  value: any;
  textError?: string;
  state?: "invalid" | "readonly";
}

interface FormData {
  id: FormInput;
  number: FormInput;
  name: FormInput;
  addrL1: FormInput;
  addrL2: FormInput;
  addrL3: FormInput;
  addrL4: FormInput;
  rmId: FormInput;
  rmNumber: FormInput;
  upn: FormInput;
  rmName: FormInput;
  statusId: FormInput;
  nostroCategoryId: FormInput;
  riskFraudId: FormInput;
  riskComplianceId: FormInput;
  valChfLimit: FormInput;
  largeTurnover: FormInput;
  plRelevant: FormInput;
  watchdog: FormInput;
  description: FormInput;
  depotRelevant: FormInput;
  totLimits: FormInput;
  orgUnit: FormInput;
  [key: string]: FormInput["value"];
}

function initFormaData(): FormData {
  return {
    id: { value: 0 },
    number: { value: "" },
    name: { value: "" },
    addrL1: { value: "" },
    addrL2: { value: "" },
    addrL3: { value: "" },
    addrL4: { value: "" },
    rmId: { value: 0 },
    rmNumber: { value: "" },
    upn: { value: "" },
    rmName: { value: "" },
    statusId: { value: 0 },
    nostroCategoryId: { value: 0 },
    riskFraudId: { value: 0 },
    riskComplianceId: { value: 0 },
    valChfLimit: { value: 0 },
    largeTurnover: { value: false },
    plRelevant: { value: false },
    watchdog: { value: false },
    description: { value: "" },
    depotRelevant: { value: false },
    totLimits: { value: 0 },
    orgUnit: { value: "" },
  };
}

interface NostroDetailDtoIdx extends NostroDetailDto {
  [key: string]: any;
}

export const AccountViewEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [accFetching, setAccFetching] = useState(false);

  const { id } = useParams();

  const [formData, setFormData] = useState<FormData>(initFormaData);

  const [accCatComboBox, setAccCatComboBox] =
    useState<Array<EntityModelNostroCategoryDto>>();

  const [riskFraudComboBox, setRiskFraudComboBox] =
    useState<Array<EntityModelNostroRiskDto>>();

  const [riskComplianceComboBox, setRiskComplianceComboBox] =
    useState<Array<EntityModelNostroRiskDto>>();

  const [statusComboBox, setStatusComboBox] =
    useState<Array<EntityModelStatusDto>>();

  //dispatch(appStateActions.error(null));

  function updateformDataImpl(field: string, subfield: string, value: any) {
    setFormData((prev: FormData) => ({
      ...prev,
      [field]: {
        ...prev[field],
        [subfield]: value,
      },
    }));
  }

  function updateformDataValue(event: any) {
    const { name, value } = event.target;

    let fixedValue = value;
    if (event.currentTarget.type === "number") {
      fixedValue = Number(value.replaceAll(",", ""));
    }

    updateformDataImpl(name, "value", fixedValue);
  }

  function focusOnInputHandler(event: any) {
    const { name } = event.target;

    updateformDataImpl(name, "textError", undefined);
    updateformDataImpl(name, "state", undefined);
  }

  useEffect(() => {
    if (id === undefined || isNaN(Number(id))) {
      return;
    }

    function getTotAccComments(): Promise<number> {
      return authorizedCall(
        NostroControllerService.getAllAccountLimitForNostro,
        {
          nostroPathId: Number(id),
          page: 0,
          size: 1000,
        },
        true
      ).then((dto: PagedModelEntityModelAccountLimitDto) => {
        return dto.page?.totalElements!;
      });
    }

    const getStatusComboBox = async () => {
      return authorizedCall(StatusControllerService.getAllStatuses, {
        page: 0,
        size: 100,
      })
        .then((resp) => {
          //console.log("resp", resp);
          setStatusComboBox(resp);
        })
        .finally(() => {});
    };

    function getNostroRiskFraudComboBox(): Promise<
      Array<EntityModelNostroRiskDto>
    > {
      const apiParams = {
        page: 0,
        size: 1000,
      };

      return authorizedCall(
        NostroRiskFraudControllerService.getAllNostroRiskFraud,
        apiParams,
        true
      ).then((dto: PagedModelEntityModelNostroRiskDto) => {
        if (dto._embedded === undefined) {
          dispatch(
            appStateActions.error(
              "Undefined Nostro Risk Fraud combobox values."
            )
          );
          return new Array<EntityModelNostroRiskDto>();
        }
        return dto._embedded!.entities!;
      });
    }

    function getNostroRiskComplianceComboBox(): Promise<
      Array<EntityModelNostroRiskDto>
    > {
      const apiParams = {
        page: 0,
        size: 1000,
      };

      return authorizedCall(
        NostroRiskComplianceControllerService.getAllNostroRiskCompliance,
        apiParams,
        true
      ).then((dto: PagedModelEntityModelNostroRiskDto) => {
        if (dto._embedded === undefined) {
          dispatch(
            appStateActions.error(
              "Undefined Nostro Risk Compliance combobox values."
            )
          );
          return new Array<EntityModelNostroRiskDto>();
        }
        return dto._embedded!.entities!;
      });
    }

    function getNostroCategoriesComboBox(): Promise<
      Array<EntityModelNostroCategoryDto>
    > {
      const apiParams = {
        page: 0,
        size: 1000,
      };

      return authorizedCall(
        NostroCategoryControllerService.getAllNostroCategories,
        apiParams,
        true
      ).then((dto: PagedModelEntityModelNostroCategoryDto) => {
        if (dto._embedded === undefined) {
          dispatch(
            appStateActions.error(
              "Undefined Account Categories combobox values."
            )
          );
          return new Array<EntityModelNostroCategoryDto>();
        }
        return dto._embedded!.entities!;
      });
    }

    setAccFetching(true);
    authorizedCall(
      NostroControllerService.getNostroDetail,
      {
        nostroPathId: Number(id),
      },
      true
    )
      .then((dto: NostroDetailDtoIdx) => {
        const newFormData: FormData = initFormaData();
        Object.keys(dto).forEach((key: string) => {
          if (newFormData[key] !== undefined) newFormData[key].value = dto[key];
        });
        getTotAccComments().then((total: number) => {
          const totLimitsFormInput: FormInput = {
            value: total,
          };
          setFormData({ ...newFormData, totLimits: totLimitsFormInput });
          //setFormData(newFormData);
        });

        getStatusComboBox();

        getNostroCategoriesComboBox().then((list) => {
          setAccCatComboBox(list);
        });

        getNostroRiskFraudComboBox().then((list) => {
          setRiskFraudComboBox(list);
        });

        getNostroRiskComplianceComboBox().then((list) => {
          setRiskComplianceComboBox(list);
        });
      })
      .catch((error: any) => {
        console.log("error in getNostroDetail", error);
      })
      .finally(() => {
        setAccFetching(false);
      });
  }, [id, dispatch]);

  function isNotEmpty(value: string) {
    if (typeof value === "number") return true;
    return value.trim() !== "";
  }

  function validate(): boolean {
    let valid: boolean = true;

    if (
      !formData.statusId ||
      formData.statusId.value === 0 ||
      (formData.statusId && !isNotEmpty(formData.statusId.value))
    ) {
      updateformDataImpl("statusId", "textError", "Required field");
      updateformDataImpl("statusId", "state", "invalid");
      valid = false;
    }

    if (
      !formData.description ||
      formData.description.value === null ||
      (formData.description && !isNotEmpty(formData.description.value))
    ) {
      updateformDataImpl("description", "textError", "Required field");
      updateformDataImpl("description", "state", "invalid");
      valid = false;
    }

    return valid;
  }

  function submitHandler() {
    if (!validate()) {
      return;
    }

    //let nostroDetailUpdateDto: NostroDetailUpdateDto = {};
    let requestBodyObj: any = {};

    Object.keys(formData).forEach((key: string) => {
      if (key !== "id") requestBodyObj[key] = formData[key].value;
    });

    setAccFetching(true);
    authorizedCall(NostroControllerService.updateNostroDetail, {
      nostroPathId: formData!.id.value,
      requestBody: requestBodyObj,
    })
      .then(() => {
        //navigate("/acc-view");
      })
      .catch((error) => {
        const err: AppError = {
          code: error.status,
          message: error.error?.toString(),
        };
        dispatch(appStateActions.error(err));
      })
      .finally(() => {
        setAccFetching(false);
      });
  }

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        { name: "View Nostro", route: "/acc-view" },
        { name: "Nostro Detail" },
      ]}
    >
      <div className="content p-4 bg-white">
        <div className="flex flex-row">
          <div className="basis-1/2">
            <p
              className="heading--three text-xl"
              data-testid="acc-view-edit-pagetitle"
            >
              Nostro Detail
            </p>
          </div>
          <div className="basis-1/2 text-right">
            <ZfTextButton
              hierarchy="tertiary"
              onClick={() => navigate(`/acc-view`)}
              size="small"
            >
              Cancel
            </ZfTextButton>
            <ZfTextButton
              onClick={submitHandler}
              data-testid="acc-view-edit-save-btn"
              size="small"
            >
              Save
            </ZfTextButton>
          </div>
        </div>

        {accFetching && (
          <div
            id="spinnerContainer"
            data-testid="spinner"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <ZfLoadingSpinner size="large" color="primary" type="infinite" />
          </div>
        )}

        <>
          <div className="mt-4 flex" data-testid="acc-form">
            <div className="w-1/6 font-bold text-lg">
              <span>ZR</span>
            </div>
            <div className="w-3/4 grid grid-cols-4 gap-4">
              {id && !isNaN(Number(id)) && (
                <>
                  <ZfInputField
                    label="NR"
                    value={String(formData?.number.value)}
                    placeholder=""
                    state="readonly"
                    data-testid="acc-view-edit-nr"
                  ></ZfInputField>

                  <ZfInputField
                    label="Name"
                    name="code"
                    data-testid="acc-view-edit-name"
                    value={String(formData?.name.value)}
                    placeholder=""
                    state="readonly"
                  ></ZfInputField>

                  <ZfInputField
                    label="Address 1"
                    value={String(formData?.addrL1.value)}
                    placeholder=""
                    state="readonly"
                    data-testid="acc-view-edit-addr-l1"
                  ></ZfInputField>

                  <ZfInputField
                    label="Address 2"
                    value={String(formData?.addrL2.value)}
                    placeholder=""
                    state="readonly"
                    data-testid="acc-view-edit-addr-l2"
                  ></ZfInputField>

                  <ZfInputField
                    label="Address 3"
                    value={String(formData?.addrL3.value)}
                    placeholder=""
                    state="readonly"
                    data-testid="acc-view-edit-addr-l3"
                  ></ZfInputField>
                </>
              )}
            </div>
          </div>

          <div className="mt-4 flex" data-testid="acc-form">
            <div className="w-1/6 font-bold text-lg">
              <span>Relationship Manager</span>
            </div>
            <div className="w-3/4 grid grid-cols-4 gap-4">
              {id && !isNaN(Number(id)) && (
                <>
                  <ZfInputField
                    label="NR"
                    value={String(formData?.rmNumber.value)}
                    placeholder=""
                    state="readonly"
                    data-testid="acc-view-edit-rm-number"
                  ></ZfInputField>

                  <ZfInputField
                    label="User ID"
                    name="code"
                    value={String(formData?.upn.value)}
                    placeholder=""
                    state="readonly"
                    data-testid="acc-view-edit-upn"
                  ></ZfInputField>

                  <ZfInputField
                    label="Name"
                    value={String(formData?.rmName.value)}
                    placeholder=""
                    state="readonly"
                    data-testid="acc-view-edit-rm-name"
                  ></ZfInputField>

                  <ZfInputField
                    label="Int.Abt."
                    value={String(formData?.orgUnit.value)}
                    placeholder=""
                    state="readonly"
                  ></ZfInputField>
                </>
              )}
            </div>
          </div>

          <div className="flex border-t mt-4 pt-4" data-testid="acc-form">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-5">
              <ZfSelectField
                name="statusId"
                label="Status"
                value={String(formData?.statusId.value)}
                errorText={formData.statusId.textError}
                state={formData.statusId.state}
                onFocus={focusOnInputHandler}
                onSelectFieldChange={updateformDataValue}
                defaultValue={0}
                data-testid="acc-view-edit-select-status"
              >
                {statusComboBox?.map((status) => (
                  <ZfSelectOption
                    value={String(status.statusId)}
                    key={status.statusId}
                  >
                    {status.statusText}
                  </ZfSelectOption>
                ))}
              </ZfSelectField>

              <ZfSelectField
                name="nostroCategoryId"
                label="Category"
                value={String(formData?.nostroCategoryId.value)}
                errorText={formData.nostroCategoryId.textError}
                state={formData.nostroCategoryId.state}
                onFocus={focusOnInputHandler}
                onSelectFieldChange={updateformDataValue}
                defaultValue={0}
                //helperText="These cars are for sale"
              >
                {accCatComboBox?.map((acc) => (
                  <ZfSelectOption value={String(acc.id)} key={acc.id}>
                    {acc.code}
                  </ZfSelectOption>
                ))}
              </ZfSelectField>

              <ZfSelectField
                name="riskFraudId"
                label="Fraud"
                value={String(formData?.riskFraudId.value)}
                errorText={formData.riskFraudId.textError}
                state={formData.riskFraudId.state}
                onFocus={focusOnInputHandler}
                onSelectFieldChange={updateformDataValue}
                defaultValue={0}
                //helperText="These cars are for sale"
              >
                {riskFraudComboBox?.map((acc) => (
                  <ZfSelectOption value={String(acc.id)}>
                    {acc.code}
                  </ZfSelectOption>
                ))}
              </ZfSelectField>

              <ZfSelectField
                name="riskComplianceId"
                label="Compliance"
                value={String(formData?.riskComplianceId.value)}
                errorText={formData.riskComplianceId.textError}
                state={formData.riskComplianceId.state}
                onFocus={focusOnInputHandler}
                onSelectFieldChange={updateformDataValue}
                defaultValue={0}
                //helperText="These cars are for sale"
              >
                {riskComplianceComboBox?.map((acc) => (
                  <ZfSelectOption value={String(acc.id)}>
                    {acc.code}
                  </ZfSelectOption>
                ))}
              </ZfSelectField>

              <ZfInputField
                label="Val Lim CHF"
                type="number"
                name="valChfLimit"
                value={String(formData?.valChfLimit.value)}
                placeholder=""
                errorText={formData.valChfLimit.textError}
                state={formData.valChfLimit.state}
                onFocus={focusOnInputHandler}
                onBlur={updateformDataValue}
              ></ZfInputField>

              <ZfInputField
                label="Spec. Lim. Count"
                type="number"
                value={String(formData?.totLimits.value)}
                placeholder=""
                state="readonly"
              ></ZfInputField>

              <ZfCheckbox
                label="Large Turnover"
                checked={formData?.largeTurnover.value}
                onCheckboxCheck={(event) => {
                  formData.largeTurnover.value = event.detail;
                }}
                name="largeTurnover"
              ></ZfCheckbox>

              <ZfCheckbox
                label="PL relevant?"
                checked={formData?.plRelevant.value}
                onCheckboxCheck={(event) => {
                  formData.plRelevant.value = event.detail;
                }}
              ></ZfCheckbox>

              <ZfCheckbox
                label="Watchdog"
                checked={formData?.watchdog.value}
                onCheckboxCheck={(event) => {
                  formData.watchdog.value = event.detail;
                }}
              ></ZfCheckbox>
            </div>
          </div>

          <div style={{ borderBottom: "1px solid #ddd" }} className="pb-3">
            <ZfTextareaField
              name="description"
              data-testid="acc-view-edit-description"
              value={String(formData?.description.value)}
              errorText={formData.description.textError}
              state={formData.description.state}
              onFocus={focusOnInputHandler}
              onBlur={updateformDataValue}
              label="Description"
              //helperText="Helper text."
              className="mt-3"
              //mandatory
              maxlength={500}
            ></ZfTextareaField>
          </div>

          <AccountViewEditTabs accId={Number(id)} />
        </>
      </div>
    </AuthenticatedLayout>
  );
};
