import { Watermark } from "../Watermark";
import env from "../../../../config/env";
import { ZfBreadcrumbs, ZfLinkButton } from "@ccx/zafire-react";
import { useNavigate } from "react-router-dom";

export type BreadcrumbElement = {
  name: string;
  route?: string;
};

export interface InfoAppProps {
  breadcrumbList: BreadcrumbElement[];
}

export const InfoApp = (props: InfoAppProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center w-full pt-4">
      <div className="w-full 2xl:w-4/5 flex">
        <div className="w-6/12 pb-4">
          <ZfBreadcrumbs>
            {props.breadcrumbList.map((element: BreadcrumbElement) => (
              <ZfLinkButton
                onClick={() => {
                  element.route && navigate(element.route);
                }}
                key={element.route}
              >
                {element.name}
              </ZfLinkButton>
            ))}
          </ZfBreadcrumbs>
        </div>
        <div className="w-6/12 pt-2 ">
          <Watermark env={env.REACT_APP_ENV} />
        </div>
      </div>
    </div>
  );
};
