import { Dispatch, SetStateAction } from "react";

export const DEFAULT_PAGE_SIZE = 5;
export const DEFAUL_PAGE = 1;

export function zfTableDefaultOptions(
  handleError: (error: boolean) => void,
  initialPage: number = 1,
  initialPageSize: number = 5
): any {
  return {
    rowHeight: 30,
    layout: "fitColumns",
    autoColumns: false,
    pagination: true,
    paginationMode: "remote",
    ajaxURL: "http://example.com",
    /*ajaxConfig: {
            headers: {
                "Authorization": "Bearer " + accessToken
            }
        },*/
    dataLoaderError: () => {
      //handleError(true);
      console.log("error");
    },
    paginationSize: DEFAULT_PAGE_SIZE,
    paginationSizeSelector: [3, 5, 10, 20, 50, 100],
    paginationInitialPage: 2, //initialPage, //deprecated
    paginationCounter: function (
      pageSize: number,
      currentRow: number,
      currentPage: number,
      totalRows: number,
      totalPages: number
    ) {
      if (totalRows === null) return;
      const startRow = (currentPage - 1) * pageSize + 1;
      const endRow = Math.min(currentPage * pageSize, totalRows);
      return `Showing rows ${startRow}-${endRow} of ${totalRows} total`;
    },
    filterMode: "remote",
    sortMode: "remote",
    dataLoader: false,
    ajaxResponse: (url: string, params: any, response: any) => {
      console.log("zf-table ajax response: \n", response);
      const tableData = {
        data: response._embedded?.entities,
        last_page: response.page?.totalPages,
        last_row: response.page?.totalElements,
      };
      return tableData;
    },
  };
}

export function tableBuiltHandler(
  event: CustomEvent<any>,
  setApiFetchingState: Dispatch<SetStateAction<boolean>>,
  cellEditedCallBack?: (cell: any, table: any) => void,
  selectedItemContext?: any
) {
  console.log("tableBuiltHandler...", { ...selectedItemContext });

  const table = event.detail;

  table.setData();

  if (cellEditedCallBack) {
    table.on("cellEdited", function (cell: any) {
      cellEditedCallBack(cell, table);
    });
  }

  table.on("dataLoading", function (data: any) {
    //setApiFetchingState(true);
    //console.log("DEBUG - zfTable event 'dataLoading'");
  });

  table.on("dataLoaded", (data: any) => {
    //setApiFetchingState(false);
    //selectRow(table, selectedItemContext);
    // Short timeout to ensure the table is fully rendered
    setTimeout(() => {
      preSelectRow(table, selectedItemContext);
    }, 10);
  });

  table.on("renderComplete", (a: any, b: any, c: any) => {
    setApiFetchingState(false);
  });

  table.on("renderStarted", function () {
    //console.log("DEBUG - zfTable event 'renderStarted'");
  });

  table.on("renderStarted", function () {
    //console.log("DEBUG - zfTable event 'renderStarted'");
  });

  table.on("rowClick", (event: PointerEvent, row: any) => {
    console.log("rowClick", event, row);

    const isRadioButton = !!row
      .getCells()[0]
      .getElement()
      .querySelector("zf-radio");

    if (!isRadioButton && row.getTable().getSelectedRows().includes(row)) {
      row.getTable().deselectRow(row);
    } else {
      row.getTable().selectRow(row);
    }
  });
}

function preSelectRow(table: any, selectedItemContext: any) {
  if (!selectedItemContext || !selectedItemContext.selected) return;

  let idx = table
    .getData()
    .findIndex((e: any) => e.id === selectedItemContext?.selected?.id);
  if (idx < 0) return;

  console.log("selecting row at index ", idx);

  const row = table.getRows()[idx];
  if (row) {
    table.selectRow(row);
  }
}
