/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReportRequest } from "../models/ReportRequest";
import type { ReportResponse } from "../models/ReportResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ReportControllerService {
  /**
   * Create report
   * @returns ReportResponse Report successfully created
   * @throws ApiError
   */
  public static createReport({
    requestBody,
  }: {
    requestBody: ReportRequest;
  }): CancelablePromise<ReportResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/report",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Unauthorized user`,
      },
    });
  }
}
