import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TabState {
    selectedTab: string;
}

const initialState: TabState = {
    selectedTab: 'comments',
};

const TabStateSlice = createSlice({
    name: 'tabState',
    initialState,
    reducers: {
        setSelectedTab: (state, action: PayloadAction<string>) => {
            state.selectedTab = action.payload;
        },
    },
});

export const { setSelectedTab } = TabStateSlice.actions;
export default TabStateSlice.reducer;