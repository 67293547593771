import { useEffect, useRef, useState } from "react";
import {
  ZfIconButton,
  ZfLoadingSpinner,
  ZfSearchField,
  ZfTable,
} from "@ccx/zafire-react";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import { useNavigate } from "react-router-dom";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import authorizedCall from "../../utils/authorizedCallUtils";
import { NostroControllerService } from "../../openapi";

const AccountBalanceMissingCob = () => {
  const navigate = useNavigate();

  const dataTable = useRef<HTMLZfTableElement>(null);
  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);

  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  /**
   *
   * @param url the url of the request
   * @param config the ajaxConfig object
   * @param params the ajaxParams object
   * @returns
   */
  async function dataTableApiCall(url: any, config: any, params: any) {
    setIsFetching(true);
    const sortArray: Array<string> =
      params.sort.length > 0
        ? [`${params.sort[0].field},${params.sort[0].dir}`]
        : [];
    let apiParams: Parameters<
      typeof NostroControllerService.getAllAccountBalanceMissingCob
    >[0] = {
      page: params.page,
      size: params.size,
      sort: sortArray,
    };

    if (searchFilter.current?.value) {
      apiParams = { ...apiParams, search: searchFilter.current?.value };
    }

    return authorizedCall(
      NostroControllerService.getAllAccountBalanceMissingCob,
      apiParams,
      true
    )
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  /**
   *
   * First configuration after renderization
   */
  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    const TABLE_COLUMNS = [
      { title: "COB Date", field: "cobDate" /*, headerFilter: 'input'*/ },
      { title: "Weekday", field: "weekday" },
      { title: "Account Balance Count", field: "count" },
    ];

    const actionColumns: [] = [];

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
    };
  }, []);

  function filteredSearch(event: any) {
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  console.log("RENDER - AccountCategories [isFetching:", isFetching, "]");

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        { name: "Other Views", route: "/other-views" },
        {
          name: "COB Date Missing Account Balance",
          route: "/other-views/cob-dt-missing",
        },
      ]}
    >
      <div className="content p-4 bg-white">
        <div
          className="flex flex-row pb-6"
          style={{ borderBottom: "1px solid #ddd" }}
        >
          <div className="basis-1/2">
            <p
              className="heading--two text-xl"
              data-testid="view-acc-cat-pagetitle"
            >
              COB Date Missing Account Balance
            </p>
          </div>
          <div className="basis-1/4">
            <ZfSearchField
              placeholder="Search ..."
              onInputChange={(e) => {
                filteredSearch(e);
              }}
              ref={searchFilter}
            ></ZfSearchField>
          </div>
          <div className="basis-1/4"></div>
          <div className="basis-1/4 flex flex-row-reverse">
            <ZfIconButton
              hierarchy="secondary"
              icon="arrow_back"
              className="mr-1"
              onClick={() => navigate(`/other-views`)}
              size="small"
            ></ZfIconButton>
          </div>
        </div>
        {error && <p className="error">Error in loading data</p>}

        {isFetching && (
          <ZfLoadingSpinner
            size="large"
            color="primary"
            type="infinite"
            style={{
              position: "absolute",
              left: "50%",
              right: "50%",
              zIndex: "100",
              top: "35%",
            }}
            data-testid="spinner"
          ></ZfLoadingSpinner>
        )}

        <div className="flex flex-row justify-center">
          <ZfTable
            ref={dataTable}
            onTableBuilt={(event) => tableBuiltHandler(event, setIsFetching)}
            className="mt-5 w-3/6"
            style={{
              pointerEvents: isFetching && "none",
              opacity: isFetching && 0.7,
            }}
            data-testid="ab-mis-cob-data-table"
          ></ZfTable>
        </div>
      </div>
    </AuthenticatedLayout>
  );
};

export default AccountBalanceMissingCob;
