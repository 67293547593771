import { FC, PropsWithChildren } from "react";
import "@ccx/foundations/variables.css";
import "@ccx/foundations/font-faces.css";
import { defineCustomElements, ZfAppContainer } from "@ccx/zafire-react";

const ZfConfig: FC<PropsWithChildren> = (props) => {
  defineCustomElements();

  return <ZfAppContainer>{props.children}</ZfAppContainer>;
};

export default ZfConfig;
