import { ApiError, CancelablePromise, OpenAPI } from "../openapi";
import { AppError, appStateActions } from "../store/appstate-slice";
import { authActions } from "../store/auth-slice";
import { setupStore } from "../store/store";

type inputParams<K> = K & { authorization: string };

type Callback<T, K> = (input: inputParams<K>) => CancelablePromise<T>;

/**
 * Inject the auth token and manage pagination of the called api service.
 * @param callback the api service to be called
 * @param input the service params
 * @param returnFullData if true, return the full response structure, instead only the data array
 * @returns
 */

export default async function authorizedCall<T, K>(
  callback: Callback<T, K>,
  input: Omit<inputParams<K>, "authorization"> & {
    page?: number;
    size?: number;
  },
  returnFullData: boolean = false
) {
  const accessToken = setupStore().getState().authSlice.authTokens?.accessToken;
  OpenAPI.TOKEN = accessToken !== undefined ? accessToken : undefined;

  return apiCall(callback, input, returnFullData);
}

export async function unauthorizedCall<T, K>(
  callback: Callback<T, K>,
  input: Omit<inputParams<K>, "authorization"> & {
    page?: number;
    size?: number;
  },
  returnFullData: boolean = false
) {
  OpenAPI.TOKEN = "";
  apiCall(callback, input, returnFullData);
}

async function apiCall<T, K>(
  callback: Callback<T, K>,
  input: {
    page?: number;
    size?: number;
  },
  returnFullData: boolean = false,
  refreshedToken: boolean = false
) {
  return callback(input as inputParams<K>)
    .then(async (data: any) => {
      //const store = setupStore();
      //store.dispatch(appStateActions.error(null));

      if (returnFullData) {
        return data;
      }

      if (typeof data?.page === "undefined") {
        return data?._embedded?.entities ?? [];
      }

      const page = data.page;

      if (page.totalPages <= page.number + 1) {
        return data?._embedded?.entities ?? [];
      }

      input.page = page.number + 2;

      const recursiveResult: T[] = await apiCall(callback, input);

      return [...data._embedded.entities, ...recursiveResult];
    })
    .catch((error: ApiError) => {
      const status = error.status;
      const errorMsg = error.body?.message;
      const message = errorMsg ? errorMsg : error.message;
      const store = setupStore();

      console.log(
        "authorizedCall error - status:",
        status,
        ", message",
        message,
        ", error:",
        error
      );

      if (status === 401) {
        store.dispatch(authActions.authError(status));
      } else {
        const err: AppError = {
          code: status,
          message: message,
        };
        store.dispatch(appStateActions.error(err));
        //return;
      }
      throw error;
    });
}
