import env from "../config/env";
import { Authentication } from "./auth-slice";
import { sha256 } from "js-sha256";

export type OidcConfigProps = {
  loginUri?: string;
  authorizePath?: string;
  clientId?: string;
  redirecUri?: string;
  scope?: string;
  codeVerifier?: string;
  codeChallenge?: string;
  codeChallengeMethod?: string;
  state?: string;
  tokenPath?: string;
  logoutPath?: string;
  idTokenHintParameterKey?: string;
  postLogoutRedirectUriParamKey?: string;
  postLogoutRedirectPath?: string;
  resource?: string;
};

export const oidcConfigProps: OidcConfigProps = {
  loginUri: "",
  authorizePath: "/oauth2/authorize/",
  clientId: "",
  redirecUri: "",
  scope: "User.Read+email+openid+offline+profile",
  codeVerifier: "",
  codeChallenge: "",
  codeChallengeMethod: "S256",
  state: "",
  tokenPath: "/oauth2/token",
  logoutPath: "/oauth2/logout",
  idTokenHintParameterKey: "id_token_hint",
  postLogoutRedirectUriParamKey: "post_logout_redirect_uri",
  postLogoutRedirectPath: "",
  resource: "",
};

export function evaluateOidcConfigProps() {
  oidcConfigProps.loginUri = env.REACT_APP_ADFS_AUTHORITY;
  oidcConfigProps.clientId = env.REACT_APP_ADFS_CLIENT_ID;
  oidcConfigProps.redirecUri = env.REACT_APP_ADFS_REDIRECT_URL;
  oidcConfigProps.postLogoutRedirectPath = env.REACT_APP_URL + "/loggedout";
  oidcConfigProps.codeVerifier = generateCodeVerifier();
  oidcConfigProps.codeChallenge = generateCodeChallenge(
    oidcConfigProps.codeVerifier
  );
  oidcConfigProps.resource = env.REACT_APP_ADFS_RESOURCE;
}

function generateCodeVerifier(): string {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  return base64UrlEncode(array);
}

function generateCodeChallenge(codeVerifier: string): string {
  const hash = sha256(codeVerifier);
  return base64UrlEncode(
    new Uint8Array(hash.match(/.{2}/g)!.map((byte) => parseInt(byte, 16)))
  );
}

function generateState(): string {
  const array = new Uint8Array(16);
  window.crypto.getRandomValues(array);
  return base64UrlEncode(array);
}

function base64UrlEncode(buffer: Uint8Array): string {
  return btoa(String.fromCharCode.apply(null, Array.from(buffer)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

export function calculateAdfsUrl() {
  const state = generateState();
  console.log("Generated state:", state);
  saveAuthParams(state, oidcConfigProps.codeVerifier!);

  let url =
    oidcConfigProps.loginUri! +
    oidcConfigProps.authorizePath +
    "?client_id=" +
    oidcConfigProps.clientId +
    "&redirect_uri=" +
    oidcConfigProps.redirecUri +
    "&response_type=code" +
    "&scope=" +
    oidcConfigProps.scope +
    "&code_challenge=" +
    oidcConfigProps.codeChallenge +
    "&code_challenge_method=" +
    oidcConfigProps.codeChallengeMethod +
    "&state=" +
    state +
    "&response_mode=query";

  url = oidcConfigProps.resource
    ? url + "&resource=" + oidcConfigProps.resource
    : url;

  console.log("Calculated ADFS URL:", url);
  return url;
}

export function saveAuthParams(state: string, codeVerifier: string) {
  try {
    sessionStorage.setItem("oauth_state", state);
    sessionStorage.setItem("code_verifier", codeVerifier);
    console.log("Auth params saved. State:", state);
  } catch (error) {
    console.error("Error saving auth params:", error);
  }
}

export function getAndClearSavedState(): string | null {
  try {
    const state = sessionStorage.getItem("oauth_state");
    console.log("Retrieved saved state:", state);
    sessionStorage.removeItem("oauth_state");
    return state;
  } catch (error) {
    console.error("Error retrieving saved state:", error);
    return null;
  }
}

export function getAndClearCodeVerifier(): string | null {
  try {
    const codeVerifier = sessionStorage.getItem("code_verifier");
    sessionStorage.removeItem("code_verifier");
    return codeVerifier;
  } catch (error) {
    console.error("Error retrieving code verifier:", error);
    return null;
  }
}

export async function authorize() {
  console.log("Starting authorization process");
  const location = window.location.href;
  console.log("Current location:", location);

  const urlParams = new URLSearchParams(location.split("?")[1]);
  const code = urlParams.get("code");
  const returnedState = urlParams.get("state");

  console.log("Returned state:", returnedState);

  const savedState = getAndClearSavedState();
  console.log("Saved state:", savedState);

  if (!savedState || returnedState !== savedState) {
    console.error(
      "State mismatch. Saved state:",
      savedState,
      "Returned state:",
      returnedState
    );
    throw new Error("Invalid state parameter. Possible CSRF attack.");
  }

  if (!code) {
    console.error("Authorization code not found in the response");
    throw new Error("Authorization code not found in the response.");
  }

  const codeVerifier = getAndClearCodeVerifier();
  if (!codeVerifier) {
    console.error("Code verifier not found");
    throw new Error(
      "Code verifier not found. Authorization process cannot continue."
    );
  }

  const payload =
    "grant_type=authorization_code" +
    "&code=" +
    code +
    "&redirect_uri=" +
    oidcConfigProps.redirecUri +
    "&code_verifier=" +
    codeVerifier +
    "&client_id=" +
    oidcConfigProps.clientId;

  console.log("Sending token request");

  const authorizePromise = await fetch(
    oidcConfigProps.loginUri! + oidcConfigProps.tokenPath,
    {
      method: "POST",
      body: payload,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

  console.log("Token request completed");

  return authorizePromise;
}

export function toLoginPage() {
  console.log("Initiating login process");
  localStorage.removeItem("authTokens");
  const loginUrl = calculateAdfsUrl();
  console.log("Redirecting to:", loginUrl);
  window.location.href = loginUrl;
}

export function logout(idToken?: string) {
  localStorage.removeItem("authTokens");

  window.location.href =
    oidcConfigProps.loginUri! +
    oidcConfigProps.logoutPath +
    "?" +
    oidcConfigProps.idTokenHintParameterKey +
    "=" +
    idToken +
    "&" +
    oidcConfigProps.postLogoutRedirectUriParamKey +
    "=";
  //+ oidcConfigProps.postLogoutRedirectUri;
}

export function readUpn(idToken?: string) {
  const splitted = idToken?.split(".");
  const decoded = atob(splitted![1]);
  // console.log("atob:\n", decoded);
  const upn = JSON.parse(decoded).upn;
  return upn;
}

export function readAuthToken(): Authentication {
  const authTokens: Authentication = JSON.parse(
    localStorage.getItem("authTokens") || "{}"
  );
  return authTokens;
}

export function checkForTokens() {
  const authTokens: Authentication = readAuthToken();
  return authTokens?.accessToken !== undefined ? true : false;
}

export async function refresh(refreshToken?: string) {
  const payload =
    "grant_type=refresh_token" +
    "&refresh_token=" +
    refreshToken +
    "&redirect_uri=" +
    oidcConfigProps.redirecUri +
    //+ '&code_verifier=' + oidcConfigProps.codeVerifier
    "&client_id=" +
    oidcConfigProps.clientId +
    "&scope=" +
    oidcConfigProps.scope;

  const refresh = await fetch(
    oidcConfigProps.loginUri! + oidcConfigProps.tokenPath,
    {
      method: "POST",
      body: payload,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return refresh;
}
