import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { useUserGrantsContext } from "features/user/hooks/useUserGrantsContext";
// import { checkOperationPermission } from "features/auth/utils/checkOperationPermission";
import { downloadCall, fetchAttachment } from "../../utils/downloadCallUtils";
import { store } from "../../store/store";

export const useReportPreview = () => {
  const [reportUrl, setReportUrl] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [reportError, setReportError] = useState("");
  const [downloadSuccess, setDownloadSuccess] = useState<undefined | boolean>();
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const [downloadReportError, setDownloadReportError] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();
  const token = store.getState().authSlice.authTokens?.accessToken;
  //   const { userGrants } = useUserGrantsContext();

  //   const downloadIsAllowed = useMemo(() => {
  //     let isPermitted = false;

  //     if (userGrants) {
  //       isPermitted = checkOperationPermission({
  //         operation: "E",
  //         component:
  //           action === "RECONCILIATION" ? "Download Recon" : "Download Stat",
  //         func: action === "RECONCILIATION" ? "DOWNLOAD_RECON" : "DOWNLOAD_STAT",
  //         userGrants,
  //       });
  //     }

  //     return isPermitted;
  //   }, [action, userGrants]);

  const getReport = useCallback(() => {
    if (id && token) {
      setReportError("");
      setIsFetching(true);

      fetchAttachment(id, token)
        .then((blob) => {
          setIsFetching(false);
          const blobUrl = URL.createObjectURL(blob);
          setReportUrl(blobUrl);
        })
        .catch((error) => {
          setIsFetching(false);
          setReportError(error?.body?.message || error?.message);
        });
    }
  }, [id, token]);

  useEffect(() => {
    getReport();
  }, [getReport]);

  const handleDownloadReport = useCallback(() => {
    setIsDownloadingReport(true);

    if (id && token) {
      setIsDownloadingReport(true);
      downloadCall(id, token, id)
        .then(() => {
          setIsDownloadingReport(false);
          setDownloadSuccess(true);
        })
        .catch((error) => {
          setIsDownloadingReport(false);
          setDownloadSuccess(false);
          setDownloadReportError(error?.body?.message || error?.message);
        });
    }
  }, [id, token]);

  const handleBack = () => navigate("/");

  return {
    reportUrl,
    downloadSuccess,
    handleDownloadReport,
    handleBack,
    isFetching,
    reportError,
    isDownloadingReport,
    downloadReportError,
    // downloadIsAllowed,
  };
};
