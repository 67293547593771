import {
  ZfIconButton,
  ZfLoadingSpinner,
  ZfSearchField,
  ZfTable,
  ZfTextButton,
} from "@ccx/zafire-react";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  EntityModelAuditDto,
  EntityModelStatusDto,
  RelationshipManagerControllerService,
  StatusControllerService,
} from "../../openapi";

const ViewRelationshipManager = () => {
  const navigate = useNavigate();

  const [error, setError] = useState(false);

  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);
  const dataTable = useRef<HTMLZfTableElement>(null);
  const [statuses, setStatuses] = useState<EntityModelStatusDto[]>();

  //const [selected, setSelected] = useState<EntityModelRelationshipManagerDto | undefined>(undefined);

  const [relManagerFetching, setRelManagerFetching] = useState<boolean>(false);

  const [lastImport, setLastImport] = useState<EntityModelAuditDto>();

  //const dispatch = useAppDispatch();

  const getStatuses = () => {
    console.log("getStatuses");

    setRelManagerFetching(true);
    //const sortArray: Array<string> = params.sort.length > 0 ? [params.sort[0].field, params.sort[0].dir] : [];
    return authorizedCall(StatusControllerService.getAllStatuses, {
      page: 0,
      size: 100,
    })
      .then((resp) => {
        console.log("resp", resp);
        setStatuses(resp);
      })
      .finally(() => {
        setRelManagerFetching(false);
      });
  };

  useEffect(() => {
    getStatuses();
  }, []);

  async function dataTableApiCall(url: any, config: any, params: any) {
    setRelManagerFetching(true);

    const sortArray: Array<string> =
      params.sort.length > 0
        ? [`${params.sort[0].field},${params.sort[0].dir}`]
        : [];

    let apiParams: Parameters<
      typeof RelationshipManagerControllerService.getAllRelationshipManagers
    >[0] = {
      page: params.page,
      size: params.size,
      sort: sortArray,
    };

    if (searchFilter.current?.value) {
      apiParams = { ...apiParams, search: searchFilter.current?.value };
    }

    return authorizedCall(
      RelationshipManagerControllerService.getAllRelationshipManagers,
      apiParams,
      true
    )
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        /*do nothing*/
      })
      .finally(() => {
        //setRelManagerFetching(false);
      });
  }

  function cellEditedHandler(cell: any, table: any) {
    const data = cell.getData();
    const value = cell.getValue();
    const initValue = cell.getInitialValue();

    if (value === undefined || value.trim() === "") {
      cell.setValue(initValue);
    }

    if (statuses) {
      const statusId = statuses.filter(
        (s) => s.statusText === cell.getValue()
      )[0].statusId;

      setRelManagerFetching(true);
      authorizedCall(
        RelationshipManagerControllerService.updateRelationshipManager,
        {
          relationshipManagerPathId: data.id,
          requestBody: { status: statusId },
        }
      )
        .then((resp: any) => {
          console.log("statuses", resp);
          //setRelManagerFetching(false);
          //table.setPageSize(options.paginationSize);
          table.setData();
        })
        .catch((error) => {
          /*do nothing*/
        })
        .finally(() => {
          //setRelManagerFetching(false);
        });
    }
  }

  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    console.log("statuses", statuses);

    const TABLE_COLUMNS = [
      { title: "ID", field: "id", maxWidth: 70 },
      { title: "Number", field: "number" /*, headerFilter: 'input'*/ },
      { title: "UserID", field: "upn" },
      { title: "Name", field: "name" /*, headerFilter: 'input'*/ },
      { title: "TelNr", field: "phone" },
      { title: "Address", field: "address" },
      { title: "Org Unit", field: "orgUnit" },
      {
        title: "Status",
        field: "statusText",
        editor: "list",
        editorParams: {
          values: statuses && statuses.map((elem: any) => elem.statusText),
        },
      },
      { title: "Created at", field: "createTs" },
      { title: "Created by", field: "createUser" },
    ];

    const actionColumns: [] = [];

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
    };

    getLastImport();
  }, [statuses]);

  function refreshTable(event: any) {
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  function updateAllRelationshipManager() {
    setRelManagerFetching(true);
    authorizedCall(
      RelationshipManagerControllerService.updateAllRelationshipManagers,
      {}
    )
      .then(() => {
        setRelManagerFetching(false);
        getLastImport();
        dataTable.current!.options = { ...dataTable.current!.options };
      })
      .catch((error) => {
        /*do nothing*/
      })
      .finally(() => {
        //setRelManagerFetching(false);
      });
  }

  function getLastImport() {
    setRelManagerFetching(true);
    authorizedCall(
      RelationshipManagerControllerService.getLastRelationshipManagersImport,
      {}
    )
      .then((resp: Array<EntityModelAuditDto>) => {
        setLastImport(resp[0]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        //setRelManagerFetching(false);
      });
  }

  console.log(
    "RENDER - ViewRelationshipManager [relManagerFetching:",
    relManagerFetching,
    "]"
  );

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        {
          name: "View Relationship Manager",
          route: "/view-reéationship-manager",
        },
      ]}
    >
      <div className="content p-4 bg-white">
        <div style={{ borderBottom: "1px solid #ddd" }}>
          <div className="flex flex-row pb-6">
            <div className="basis-1/2">
              <p
                className="heading--two text-lg"
                data-testid="view-relationship-manager-pagetitle"
              >
                View Relationship Manager
              </p>
            </div>
            <div className="basis-1/4">
              <ZfSearchField
                placeholder="Search ..."
                onInputChange={(e) => {
                  refreshTable(e);
                }}
                ref={searchFilter}
              ></ZfSearchField>
            </div>

            <div className="basis-1/4 flex flex-row-reverse">
              <ZfTextButton
                disabled={relManagerFetching === true}
                onClick={updateAllRelationshipManager}
                size="small"
              >
                Update
              </ZfTextButton>
              <ZfIconButton
                hierarchy="secondary"
                icon="arrow_back"
                className="mr-2"
                onClick={() => navigate(`/`)}
                size="small"
              ></ZfIconButton>
            </div>
          </div>
        </div>
        <div className="mt-4 mb-3 text-sm">
          <label className="font-semibold">Last import by:</label>
          <label className="font-light pl-2">{lastImport?.createUser}</label>
          <label className="font-semibold ml-2">at:</label>
          <label className="font-light pl-2">{lastImport?.createTs}</label>
        </div>
        {error && <p className="error">Error in loading data</p>}
        {relManagerFetching && (
          <ZfLoadingSpinner
            size="large"
            color="primary"
            type="infinite"
            style={{
              position: "absolute",
              left: "50%",
              right: "50%",
              zIndex: "100",
              top: "35%",
            }}
            data-testid="spinner"
          ></ZfLoadingSpinner>
        )}

        <ZfTable
          ref={dataTable}
          onTableBuilt={(event) =>
            tableBuiltHandler(event, setRelManagerFetching, cellEditedHandler)
          }
          // onTableRowSelectionChanged={rowSelectionChanged}
          className="mt-5"
          data-testid="vrm-data-table"
          style={{
            pointerEvents: relManagerFetching && "none",
            opacity: relManagerFetching && 0.7,
          }}
        ></ZfTable>
      </div>
    </AuthenticatedLayout>
  );
};

export default ViewRelationshipManager;
