import type { ReactNode } from "react";
import { PageLayout } from "../PageLayout";
import { ZfDropdown, ZfDropdownItem, ZfNavbar } from "@ccx/zafire-react";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { authActions } from "../../../../store/auth-slice";
import { BreadcrumbElement, InfoApp } from "../InfoApp";

export interface AuthenticatedLayoutProps {
  children: ReactNode;
  breadcrumbList: BreadcrumbElement[];
}

export const AuthenticatedLayout = ({
  children,
  breadcrumbList,
}: AuthenticatedLayoutProps) => {
  const authTokens = useAppSelector((state) => state.authSlice.authTokens);

  const error = useAppSelector((state) => state.appStateSlice.error);

  const dispatch = useAppDispatch();

  function logout() {
    dispatch(authActions.signOut());
  }
  return (
    <PageLayout>
      <ZfNavbar
        data-testid="appname"
        appName="monitoring internal nostro account"
      >
        <div slot="actions">
          <ZfDropdown
            buttonSize="medium"
            buttonHierarchy="tertiary"
            buttonIcon="account_circle"
            data-testid="account_circle"
          >
            <div className="body">
              <div className="text-xs p-1 pl-3 pr-3">
                user id: {authTokens?.upn}
              </div>
            </div>
          </ZfDropdown>
          <ZfDropdown
            buttonSize="medium"
            buttonHierarchy="tertiary"
            buttonIcon="logout"
          >
            <ZfDropdownItem
              onClick={logout}
              label="Logout"
              className="text-xs"
            ></ZfDropdownItem>
          </ZfDropdown>
        </div>
      </ZfNavbar>
      <div className="p-1 px-8 p">
        <InfoApp breadcrumbList={breadcrumbList} />

        {error && (
          <p className="error">
            Application error with code {error.code} - {error.message}
          </p>
        )}
        <div className="flex justify-center w-full">
          <div className="w-full 2xl:w-4/5">{children}</div>
        </div>
      </div>
    </PageLayout>
  );
};
