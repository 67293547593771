import {
  ZfLoadingSpinner,
  ZfSearchField,
  ZfTable,
  ZfTextButton,
} from "@ccx/zafire-react";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  EntityModelNostroCommentDto,
  NostroControllerService,
} from "../../openapi";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { setSelectedNostroComment } from "../../store/selected-item-slice-nostro-comment";
import { ZfTableCustomEvent } from "@ccx/zafire";

export const AccountViewEditComments: FC<{ accId: number }> = (props) => {
  const navigate = useNavigate();

  const [error, setError] = useState(false);

  const dataTableComments = useRef<HTMLZfTableElement>(null);

  const dispatch = useAppDispatch();

  const selectedFromRedux = useAppSelector(
    (state) => state.selectedNostroCommentSlice
  );

  const [selected, setSelected] = useState<
    EntityModelNostroCommentDto | undefined
  >(selectedFromRedux.selected || undefined);

  const [commFetching, setCommFetching] = useState<boolean>(true);

  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);

  const dataTableCommentsApiCall = useCallback(
    (url: any, config: any, params: any) => {
      setCommFetching(true);

      const sortArray: Array<string> =
        params.sort.length > 0
          ? [`${params.sort[0].field},${params.sort[0].dir}`]
          : [];

      let apiParams: Parameters<
        typeof NostroControllerService.getAllCommentsForNostro
      >[0] = {
        page: params.page,
        size: params.size,
        sort: sortArray,
        nostroPathId: props.accId,
        search: searchFilter.current?.value,
      };

      return authorizedCall(
        NostroControllerService.getAllCommentsForNostro,
        apiParams,
        true
      )
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          /*do nothing*/
        })
        .finally(() => {
          setCommFetching(false);
        });
    },
    [props.accId]
  );

  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    const TABLE_COLUMNS = [
      {
        formatter: "zfRadioRowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 50,
      },
      { title: "ID", field: "id", maxWidth: 70 },
      { title: "Status", field: "nostroCommentStatusText" },
      { title: "User Name", field: "createUser" /*, headerFilter: 'input'*/ },
      { title: "Date", field: "createTs" },
      {
        title: "Show on report",
        field: "showOnReport",
        formatter: (cell: any) => {
          return `<zf-checkbox checked=${cell.getValue()} disabled="true"></zf-checkbox>`;
        },
      },
      { title: "File link", field: "link" },
      { title: "Comment", field: "comment" },
    ];

    const actionColumns: [] = [];

    setError(false);
    dataTableComments.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableCommentsApiCall,
      selectable: 1,
    };
  }, [props.accId, dataTableCommentsApiCall]);

  /*function refreshTable(event: any) {
        dataTableComments.current!.options = { ...dataTableComments.current!.options };
    }*/

  const rowSelectionChanged = useCallback(
    (event: ZfTableCustomEvent<any>) => {
      //row - row component
      const selected: EntityModelNostroCommentDto = event.detail.data[0];
      setSelected(selected);
      dispatch(
        setSelectedNostroComment({
          ...selectedFromRedux,
          selected,
        })
      );
      console.log(
        "rowSelectionChanged: ",
        selected,
        "page",
        selectedFromRedux.page,
        "page size:",
        selectedFromRedux.pageSize
      );
    },
    [dispatch, selectedFromRedux]
  );

  const tableBuiltHandlerCallback = useCallback(
    (event: any) => {
      const table = event.detail;

      table.on("pageLoaded", (pageNo: number) => {
        dispatch(
          setSelectedNostroComment({
            ...selectedFromRedux,
            page: pageNo,
          })
        );
      });

      table.on("pageSizeChanged", (size: number) => {
        dispatch(
          setSelectedNostroComment({
            ...selectedFromRedux,
            pageSize: size,
          })
        );
      });
      tableBuiltHandler(event, setCommFetching, undefined, selectedFromRedux);
    },
    [selectedFromRedux, dispatch]
  );

  function filteredSearch(event: any) {
    dataTableComments.current!.options = {
      ...dataTableComments.current!.options,
    };
  }

  /*console.log(
    "RENDER - AccountViewEditComments [commFetching:",
    commFetching,
    "]"
  );*/

  return (
    <>
      <div>
        <div className="flex flex-row-reverse">
          <ZfTextButton
            hierarchy="primary"
            onClick={() => navigate(`/acc-comment-edit/${props.accId}/new`)}
            size="small"
          >
            Create
          </ZfTextButton>
          <ZfTextButton
            disabled={selected === undefined}
            onClick={() =>
              navigate(`/acc-comment-edit/${props.accId}/${selected?.id}`)
            }
            hierarchy="secondary"
            className="mr-1"
            data-testid="acc-view-edit-comm-edit-btn"
            size="small"
          >
            Edit
          </ZfTextButton>
          <div className="basis-1/4 mr-12">
            <ZfSearchField
              placeholder="Search ..."
              onInputChange={(e) => {
                filteredSearch(e);
              }}
              ref={searchFilter}
            ></ZfSearchField>
          </div>
        </div>
      </div>

      {error && <p className="error">Error in loading data</p>}

      {commFetching && (
        <div
          id="spinnerContainer"
          data-testid="acc-view-edit-comm-spinner"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <ZfLoadingSpinner size="large" color="primary" type="infinite" />
        </div>
      )}

      <ZfTable
        ref={dataTableComments}
        onTableBuilt={(event) => tableBuiltHandlerCallback(event)}
        onTableRowSelectionChanged={rowSelectionChanged}
        className="mt-5"
        data-testid="acc-view-edit-comm-data-table"
        //onTableRowSelected={(event) => console.log("row click", event)}
        style={{
          pointerEvents: commFetching && "none",
          opacity: commFetching && 0.7,
        }}
      ></ZfTable>
    </>
  );
};
